$primary: #FFD800!default;
$secondary: #F2F2F4 !default;
$error: #ce2029 !default;
$info: #ff6b00 !default;
$success: #009739 !default;
$spacing: 8;
$black: #000 !default;
$white: #fff !default;

$primary-border: #ffcd0080 !default;
$secondary-border: #888888 !default;

$primary-horver-background-color: #fff9e1 !default;
$secondary-hover-background-color: #f4f4f4 !default;
$primary-contained-horver-background-color: #ffe26b !default;

$primary-active-background-color: #ffbc00 !default;
$secondary-active-background-color: #000000 !default;
$primary-contained-active-background-color: #ffbc00 !default;

$basic-font: Spoqa Han Sans Neo, Helvetica, Arial, sans-serif !default;

@keyframes animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animation-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animation-right {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes animation-blow-up-modal {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
@keyframes animation-rotate-img {
    100% {
        transform: rotate(360deg);
    }
}
