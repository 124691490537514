@import "variables.scss";
@import "defaultLayout.scss";

$checkbox-on: url("../img/atoms/checkbox/button-chek-on@3x.png");

/* 로딩 */
#bgLoder {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-tap-highlight-color: transparent;

    & svg {
        animation: animation-rotate-img 4s linear infinite;
        transform-origin: 50% 50%;
        font-size: 6.25rem; // 100px
    }
}

/* 버튼 */
body .rev-button-root {
    text-transform: unset;
    background-color: transparent;
    font-weight: bold;
    color: rgba(49, 39, 39, 0.87);
    font-size: 0.8125rem; // 13px
    min-width: 54px;
    box-sizing: border-box;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    outline: 0;
    padding: 10px 18px;

    &.MuiButton-contained:hover {
        box-shadow: unset;
    }

    &.outlined {
        border: 10.5px solid #000;
        background-color: #fff;

        &:active {
            color: #fff;
        }

        &.primary {
            border: 1px solid $primary-border;

            &:hover {
                background-color: $primary-horver-background-color;
            }

            &:active {
                background-color: $primary-active-background-color;
            }
        }

        &.secondary {
            border: 1px solid $secondary-border;

            &:hover {
                background-color: $secondary-hover-background-color;
            }

            &:active {
                & span.button-label {
                    color: #fff;
                }

                background-color: $secondary-active-background-color;
            }
        }
    }

    &.contained {
        box-shadow: unset;

        &.primary {
            background-color: $primary;

            &:hover {
                background-color: $primary-contained-horver-background-color;
                // box-shadow: 0 4px 16px 0 rgba(255, 205, 0, 0.28);
            }

            &:active {
                background-color: $primary-contained-active-background-color;
            }
        }

        &.secondary {
            background-color: $secondary;
        }

        @mixin disabled-button {
            background-color: #eeeeee;

            & .button-label {
                color: #bebebe;
            }
        }

        &.disabled {
            @include disabled-button;

            &:hover {
                @include disabled-button;
            }
        }
    }

    .button-label {
        font-size: 0.8125rem; // 13px
        min-width: 36px;
        height: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.11px;
        text-align: center;
        color: #000000;
        font-family: $basic-font;

        &.primary {
            color: $primary;
        }

        &.secondary {
            color: $secondary;
        }

        &.black {
            color: $black;
        }

        &.white {
            color: $white;
        }
    }
}

/* input */
.rev-input-root {
    display: inline-flex;
    font-family: $basic-font;
    font-size: 0.8125rem; // 13px
    padding: 9px 12px;
    border-radius: 4px;
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    outline-color: #fff;
    float: left;
    border-radius: 4px;
    margin-left: 0px;

    img {
        cursor: pointer;
        vertical-align: middle;

        &.cancel {
            visibility: hidden;
            float: left;
            display: block;
            width: auto;
            margin-top: 1px;
        }

        &.searchActive {
            visibility: hidden;
            float: right;
            display: block;
        }

        &.search {
            display: inline;
            float: left;
            display: block;
            margin-right: 4px;
            width: auto;
            height: 100%;
        }
    }

    &.focus {
        border: solid 1.5px $primary;

        img {
            &.search {
                display: none;
            }

            &.searchActive {
                visibility: visible;
                width: auto;
                height: 100%;
                margin-top: -2px;
                width: auto;
                height: 18px;
                margin-left: 12px;
            }
        }

        &.x {
            img {
                &.cancel {
                    visibility: visible;
                }
            }
        }
    }

    input {
        font-family: $basic-font;
        display: block;
        width: 100%; //calc(100% - 21px);
        text-align: left;
        float: left;
        padding: 0px;
        border: 0px;
        caret-color: #fff;
        background: none;
        box-sizing: content-box;
        font-size: 0.8125rem; // 13px
        color: #000;
        height: 100%;

        &:focus-within {
            caret-color: $primary;
            outline: 0;
        }

        &::placeholder {
            color: #999;
        }

        &:disabled {
            color: #000;
            -webkit-text-fill-color: #000;
            opacity: 1;
            font-family: $basic-font;
        }
    }
}

/* 기본 입력란에 사용되는 input */
.rev-inputDefault-root {
    transition: all 0.25s ease;
    display: flex;
    min-width: fit-content;
    width: inherit;
    padding: 8px 12px;

    &.disabled {
        background-color: #f7f7f7;
        border: 0px;
        padding: 9.5px 12px;
    }

    img {
        &.essential {
            display: none;
            width: auto;
            margin-top: 1px;
        }

        &.check {
            display: none;
            margin-top: 1px;
        }
    }

    &.exist:not(.disabled):not(.focus) {
        img {
            &.check {
                display: inline;
            }
        }
    }

    &.valid {
        border: solid 1px #ff0000;

        & img {
            &.essential {
                display: inline;
            }
        }
    }
}

.essentialComment {
    font-size: 0.625rem; // 10px
    color: #ff0000;
    padding-left: 13px;
    display: flex;
    width: 100%;
    height: auto;
    margin: 0px;
    padding-top: 4px;
}

/* 토스트 */
.rev-toast-container-root {
    right: 0;
    // top: 122px;
    z-index: 9999;
    position: fixed;
    padding: 4px;
    min-width: 250px;
    box-sizing: border-box;
    font-size: 0.8125rem; // 13px
    padding: 0px;
    padding-right: 5px;

    top: 50%;
    left: 50%;
    // padding: 0px 20px 0px 30px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 480px;
    min-width: 320px;
    width: 100%;

    & .rev-toast-body {
        transform: translate(-50%, -50%);
        position: fixed;
        top: 50%;
        left: 50%;
        width: 80%;
        text-align: center;

        & .rev-toast-item {
            animation-name: animation-fade-in;
            animation-duration: 0.5s;
            cursor: pointer;
            padding: 10px;
            background: rgba(51, 51, 51, 0.8);
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            margin-bottom: 10px;

            &.fadeOut {
                animation-name: animation-fade-out;
                animation-duration: 0.5s;
                opacity: 0;
            }

            div {
                margin: 0px;
                padding: 0px;
                font-family: $basic-font;

                &.tit {
                    color: #fff;
                    font-size: 0.75rem; // 12px

                    // font-weight: bold;
                    &.msg {
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: solid 0.5px #dfdfdf;
                    }

                    & img {
                        &.toastIcon {
                            float: left;
                            vertical-align: middle;
                            margin-right: 5px;
                        }

                        &.toastCancelIcon {
                            float: right;
                            margin-top: 3px;
                        }
                    }
                }

                &.con {
                    display: block;
                    top: 50%;
                    font-size: 0.625rem; // 10px
                    color: #fff;
                    letter-spacing: -0.1px;
                    max-height: 70vh;
                    overflow-y: auto;

                    & span {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }

            &.default {
                background-color: #000000bd;
            }

            &.info {
                background-color: #000000bd;
            }

            &.error {
                background-color: #000000bd;
            }

            &.warning {
                background-color: #000000bd;
            }

            &.success {
                background-color: #000000bd;
            }
        }
    }
}

/* 체크박스 */
.rev-checkBoxoptionGroup-root {
    label {
        display: inline-flex;
        margin-right: 17px;
        margin-bottom: 15px;
        cursor: pointer;

        input {
            width: 100%;
            height: 14px;
            border-radius: 2px;
            border: solid 1px #cccccc;
            appearance: none;
            outline: none;
            vertical-align: middle;
            margin: 0px 3px 0px 0px;
            cursor: pointer;

            &.check {
                background: transparent;
                background-image: $checkbox-on;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 16px 16px;
                border: none;
            }
        }
    }
}

/* 모달 */
.rev-modal-root {
    display: none;
    font-family: $basic-font;

    &.dialog {
        .rev-modal-background-root {
            z-index: 3;
        }

        .rev-modal-contents {
            z-index: 4;
        }
    }

    & .rev-modal-background-root {
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.75);
        -webkit-tap-highlight-color: transparent;
        transition: none;
    }

    &.open {
        overflow: hidden;
        display: block;

        .rev-modal-contents {
            overflow-y: auto;
            z-index: 3;
            border-radius: 4px;
            animation-name: animation-fade-in;
            animation-duration: 0.3s;
            width: inherit;
            height: inherit;
            position: fixed;
            height: 164px;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
                0px 1px 14px 0px rgba(0, 0, 0, 0.12);
            background-color: #fff;
            top: 50%;
            left: 50%;
            // padding: 0px 20px 0px 30px;
            padding: 20px 20px 30px 30px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            &>img.closeIcon {
                visibility: hidden;
                float: right;
                // margin-top: 20px;
                cursor: pointer;

                &.close {
                    visibility: visible;
                }
            }
        }
    }
}

/* 다이얼로그 */
.rev-dialog-root {
    display: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    &.on {
        display: block;

        & h4 {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 14px;
        }

        .rev-modal-contents {
            overflow-y: hidden;
            padding: 0px;

            img.closeIcon {
                display: none;
            }

            .cDialog {
                flex-grow: 0;
                max-width: 100%;
                display: flex;
                flex-flow: column;
                height: 100%;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                letter-spacing: -0.5px;

                .con {
                    font-weight: 500;
                    font-size: 1.25rem; //20px
                    line-height: 30px;
                    text-align: center;
                }

                .cButton {
                    height: 60px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    button {
                        border-radius: 0px;
                        width: 50%;
                        height: 100%;
                        border: 0px;

                        .button-label {
                            font-weight: 400;
                            font-size: 0.9375rem; //15px
                        }

                        &:first-child {
                            background: #F2F2F4;
                            border-radius: 0px 0px 0px 4px;
                        }

                        &:last-child {
                            .button-label {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 드롭다운박스 */
.rev-selectbox-root {
    width: 132px;
    position: relative;
    display: inline-block;
    border-radius: 4px;

    &.disabled {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 0px;
        padding: 9.5px 12px;

        &.valid {
            border: solid 1px #ff0000;

            & img {
                &.essential {
                    display: inline;
                }
            }
        }
    }

    p {
        display: block;
        width: 100%;
        margin: 0px;
        padding: 9.5px;
        border-radius: 4px;
        border: solid 1px #dfdfdf;
        background-color: #ffffff;
        cursor: pointer;
        text-align: left;

        & span {
            display: inline !important;
            font-size: 13px;
        }

        & img {
            float: right;
        }
    }

    & .bg {
        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 0;

        &.active {
            display: block;
            z-index: 1;
        }
    }

    & ul {
        list-style: none;
        display: none;
        padding-left: 0px;

        &.active {
            max-height: 400px;
            overflow-y: auto;
            margin: 0px;
            display: block;
            position: absolute;
            z-index: 2;
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.18);
            background-color: #ffffff;
            padding: 4.8px;
            margin-top: -40px;

            li {
                display: inline-flex;
                width: 100%;
                cursor: pointer;
                background-color: #ffffff;
                margin-bottom: 5px;

                p {
                    display: block;
                    height: 30px;
                    border-radius: 4px;
                    background-color: #ffffff;
                    padding: 0px;
                    border: 0px;
                    transition: all 0.25s ease;
                    padding-top: 8px;
                }

                &.placeholder {
                    &:hover {
                        p {
                            background-color: transparent;
                        }
                    }

                    p {
                        color: #888888;
                        padding-left: 14px;
                        cursor: initial;
                    }
                }

                & img {
                    float: left;
                    visibility: hidden;
                    margin-right: 1px;

                    &.active {
                        visibility: visible;
                    }
                }

                &:hover {
                    p {
                        background-color: rgba(255, 205, 0, 0.14);
                    }
                }
            }
        }
    }
}

// 채팅 영역
.rev-chat.cChat {

    &.button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 54px;
        width: 100%;
        margin: 0px 20px;
        background: #FFCD00;
        border-radius: 12px;
        font-size: 1.125rem; // 18px;
        font-weight: 700;

        a {
            display: flex;
            align-items: center;

            img {
                margin-right: 9.64px;
            }
        }

    }

    &.circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 118px;
        right: 20px;
        z-index: 2;
        text-align: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #F9E000;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        img {
            margin-top: 5px;
        }
    }
}

/* 팁메세지 */
.rev-tip {
    position: absolute;
    bottom: 0px;
    width: 100%;

    .cData {
        position: relative;
        background-color: #f0edde;
        border: 2px solid #b8c4d0;
        text-align: center;
        padding: 10px;
        font-size: 0.6875rem; //11px

        img.xIcon {
            cursor: pointer;
            position: absolute;
            right: 10px;
        }

        p {
            margin: 0px;

            &.title {
                font-weight: 900;
            }
        }
    }
}

/* 모바일 가로, 모바일 세로 (해상도 ~ 1066px)*/
@media all and (max-width: 1066px) {}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {}