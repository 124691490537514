@import "variables.scss";

$frame: url("../img/main/frame-244@3x.png");

html,
body,
#root {
    touch-action: none;
    letter-spacing: -0.13px;
    -webkit-tap-highlight-color: transparent;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    line-height: initial;
    font-size: 16px;
    background: #fff;
    height: auto;
    max-width: 480px;
    min-width: 320px;
}

* {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

button,
input,
optgroup,
select,
textarea {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: 0;
    font-family: $basic-font;
}

/* 전체 */
.root {
    animation-name: animation-fade-in;
    animation-duration: 0.5s;
    font-size: 0.8125rem; // 13px
    flex-grow: 1;
    height: 100vh;
    font-family: $basic-font;

    a {
        cursor: pointer;
    }

    a:visited,
    a:link {
        text-decoration: none;
        color: #000;
    }

    li {
        transition: all 0.25s ease;
    }

    .data {
        overflow-y: auto;
        height: 100vh;
    }
}


/* PC (해상도 1024px ~ )*/
@media all and (min-width: 1024px) {}

@media all and (min-width: 768px) and (max-width: 1115px) {}

/* 가로모드 모바일 디바이스 (가로 해상도가 480px 보다 큰 화면에 적용) */
@media (min-width: 480px) {
    #root {
        .root {
            width: 480px;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0%);

            .data {
                display: flex;
            }
        }
    }
}

/* 태블릿 디바이스 (가로 해상도가 768px 보다 큰 화면에 적용) */
@media (min-width: 768px) {}

/* 테스크탑 (가로 해상도가 992px 보다 큰 화면에 적용) */
@media (min-width: 992px) {}

/* 큰화면 데스크탑 (가로 해상도가 1200px 보다 큰 화면에 적용) */
@media (min-width: 1200px) {}